import {
    required,
    maxLength
} from 'vuelidate/lib/validators';

const nameLength = (value) => {
    let Array = value.split(' ');
    for (let index = 0; index < Array.length; index++) {
      if(Array[index].length > 20){
        return false
      };
    }
    return true;
};

export default () => {
    return {
        IncidenceGpoNameEn: { required, maxLength: maxLength(60), nameLength },
        IncidenceGpoNameEs: { required, maxLength: maxLength(60), nameLength },
        Status: { required },
    }
}