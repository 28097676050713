<template>
  <form @submit.prevent="submitBody">
    <loading-overlay
      :active="apiStateLoading"
      :is-full-page="true"
      loader="bars"
    />
    <CModalExtended
      :title="titleModal"
      color="dark"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
      @update:show="$emit('close');"
    >
      <CRow>
        <CCol sm="12" lg="12">
          <CInput
            v-uppercase
            :label="$t('label.name')+' '+'(ES)'"
            :placeholder="$t('label.incidenceGroupName')+' '+'(ES)'"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
            v-model="$v.IncidenceGpoNameEs.$model"
            addLabelClasses="required text-right"
            :is-valid="hasError($v.IncidenceGpoNameEs)"
            :invalid-feedback="errorMessage($v.IncidenceGpoNameEs)"
          />
        </CCol>
        <CCol sm="12" lg="12">
          <CInput
            v-uppercase
            :label="$t('label.name')+' '+'(EN)'"
            :placeholder="$t('label.incidenceGroupName')+' '+'(EN)'"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
            v-model="$v.IncidenceGpoNameEn.$model"
            addLabelClasses="required text-right"
            :is-valid="hasError($v.IncidenceGpoNameEn)"
            :invalid-feedback="errorMessage($v.IncidenceGpoNameEn)"
          />
        </CCol>
        <CCol sm="12" lg="12" v-if="isEdit">
          <CSelect
            :options="statusOptions"
            :value.sync="Status"
            :label="$t('label.status')"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
            addLabelClasses="required text-right"
            :is-valid="statusSelectColor"
          />
        </CCol>
      </CRow>

      <template #footer>
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
           @click.stop="isEdit ? statusConfirmation(IncidenceGpoItem.FgActIncidenceGpo , Status, submitBody) : submitBody()"
          :disabled="isSubmit"
        >
          <CIcon name="checkAlt"/>{{$t('button.accept')}}
        </CButton>
        <CButton
          square
          color="wipe"
          class="d-flex align-items-center"
          @click="$emit('close');"
        >
          <CIcon name="x" /><span class="ml-1"
            >{{$t('button.cancel')}}</span
          >
        </CButton>
      </template>
    </CModalExtended>
  </form>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import incidenceGrpMasterValidation from '@/_validations/incidence/incidenceGrpMasterValidation';
import UpperCase from '@/_validations/uppercase-directive';

function data() {
  return {
    isSubmit: false,
    modalActive: false,
    apiStateLoading: false,
    IncidenceGpoId: '',
    IncidenceGpoNameEn: '',
    IncidenceGpoNameEs: '',
    Status: 0,
  };
}

function submitBody() {
  try {
    this.isSubmit = true;
    this.$v.$touch();
    this.apiStateLoading = true;
    if (this.$v.$error) {
      this.isSubmit = false;
      this.apiStateLoading = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    const IncidenceGpoJson = this.isEdit ? {
      IncidenceGpoId: this.IncidenceGpoId,
      IncidenceGpoNameEn: this.IncidenceGpoNameEn,
      IncidenceGpoNameEs: this.IncidenceGpoNameEs,
      Status: this.Status,
    }:{
      IncidenceGpoNameEn: this.IncidenceGpoNameEn,
      IncidenceGpoNameEs: this.IncidenceGpoNameEs,
    };
    let metodo = this.isEdit ? 'PUT':'POST';
    let ruta = this.isEdit ? 'IncidenceGpo-update': 'IncidenceGpo-insert';
    this.$http
      .ejecutar( metodo, ruta, IncidenceGpoJson, { root: 'IncidenceGpoJson' })
      .then((response) => {
        if (response && response.status === (200 || 201)) {
          const messageSuccess = response.data.data[0].Response;
          this.apiStateLoading = false;
          this.isSubmit = false;
          this.closeModal();
          this.$emit('Update-list');
          this.notifySuccess({text: messageSuccess})
        } 
      }).catch((e) => {
        this.isSubmit = false;
        this.apiStateLoading = false;
        this.notifyError({text: e});
      });
  } catch (e) {
    this.isSubmit = false;
    this.apiStateLoading = false;
    this.notifyError({text: e});
  }
}

function titleModal() {
  if (this.isEdit) {
    let _lang = this.$i18n.locale;
    let title = _lang=='en' ? this.IncidenceGpoItem.IncidenceGpoNameEn : this.IncidenceGpoItem.IncidenceGpoNameEs;
    return this.$t('label.edit')+' '+this.$t('label.IncidenceGroup')+': '+title;
  } else {
    return this.$t('label.nuevo')+' '+this.$t('label.IncidenceGroup');
  }
}

function statusSelectColor() {
  return this.Status === 1;
}

function getdata(val) {
  this.IncidenceGpoNameEs = val.IncidenceGpoNameEs;
  this.IncidenceGpoNameEn = val.IncidenceGpoNameEn;
  this.IncidenceGpoId = val.IncidenceGpoId;
  this.Status = val.Status == 'ACTIVO' ? 1 : 0;
  this.$v.$touch();
}

function closeModal() {
  this.IncidenceGpoNameEs = '';
  this.IncidenceGpoNameEn = '';
  this.IncidenceGpoId = '';
  this.Status = 0;
  this.$v.$reset();
  //this.$emit('Update-list');
}

/*function OrderUpdateListFormatted(){
  return [{
    value: '', 
    label: this.$t('label.select'),
  },{
    value: 1, 
    label: this.$t('label.yes'),
  },{
    value: 0, 
    label: 'NO',
  }];
}*/

export default {
  name: 'modal-group-incidence',
  props: { modal: Boolean, isEdit: Boolean, IncidenceGpoItem: Object },
  data,
  mixins: [
    ModalMixin,
  ],
  validations: incidenceGrpMasterValidation,
  directives: UpperCase,
  methods: {
    statusSelectColor,
    closeModal,
    getdata,
    submitBody,
  },
  computed: {
    titleModal,
  },
  watch: {
    modal: function(val){
      this.modalActive = val;
      if (this.isEdit==true) {
        this.getdata(this.IncidenceGpoItem);
      }else{
        this.closeModal();
      }
    }
  },
 
};
</script>